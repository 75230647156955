<script>
import {mapActions, mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "articles-by-teva",

  data: () => ({
    page: 1,
    drop: false,
    showFilter: false,
    text: "",
    filterCtrl: {
      dropFilter: 'byPriorityASC',
      all: true,
      aF: [],
      nF: [],
      dF: [],
    },
    hexTokens: {
      S: {
        pattern: /[а-яА-Я-ІіЇїєЄ']/
      }
    }
  }),

  computed: {
    ...mapGetters([
      "getArticleNosologies",
      "getArticles",
      "getArticleNum",
      "getUsefulTotalPages"
    ])
  },

  watch: {
    'text'() {
      this.sortClick();
    }
  },

  created() {

    let aF = '';
    let nF = '';
    let dF = '';

    if (Object.keys(this.$route.query).length !== 0) {

      if (this.$route.query.a !== undefined) {
        aF = this.$route.query.a;
        if (aF === '') {
        } else {
          aF.split(',').forEach((value) => {
            this.filterCtrl.aF.push(parseInt(value));
          })
          this.filterCtrl.all = false
        }
      }

      if (this.$route.query.n !== undefined) {
        nF = this.$route.query.n;

        if (nF === '') {
        } else {
          nF.split(',').forEach((value) => {
            this.filterCtrl.nF.push(parseInt(value));
          })
          this.filterCtrl.all = false
        }
      }

      if (this.$route.query.d !== undefined) {
        dF = this.$route.query.d;
        if (dF === '') {
        } else {
          dF.split(',').forEach((value) => {
            this.filterCtrl.dF.push(parseInt(value));
          })
          this.filterCtrl.all = false
        }
      }
    }

    document.title = "Корисне";


    this.getAllArticles({
      dataFilter: {value1: this.filterCtrl.dropFilter, value2: this.text},
      countNext: 8,
      url: "useful",
      pageNext: {
        page: 1,
        type: 'single'
      }
    })

  },

  methods: {
    ...mapActions([
      "getAllArticles",
      "getNewArticlesPage",
      "sortArticles",
    ]),

    addFilter(data) {

      if (data.type !== 'all') {
        this.filterCtrl.all = false
        if (!this.filterCtrl[data.type].includes(data.id)) {
          this.filterCtrl[data.type].push(data.id)
        } else {
          this.filterCtrl[data.type].splice(this.filterCtrl[data.type].indexOf(data.id), 1);
          if (this.filterCtrl[data.type].length === 0) {
            this.filterCtrl.all = true
            this.filterCtrl.nF = []
          }
        }
      } else {
        this.filterCtrl.all = true
        this.filterCtrl.nF = []
      }

      this.$router.replace({
        name: this.$route.name,
        query: {
          a: this.filterCtrl.aF.toString(),
          d: this.filterCtrl.dF.toString(),
          n: this.filterCtrl.nF.toString(),
        }
      })

      this.getAllArticles({
        dataFilter: {value1: this.filterCtrl.dropFilter, value2: this.text},
        countNext: 8,
        url: "useful",
        pageNext: {
          page: 1,
          type: 'single'
        }
      })
    },

    goToArticle(id) {
      this.$router.push({name: 'page', params: {id: id}})
    },

    navigation(data) {
      this.getAllArticles({
        dataFilter: {value1: this.filterCtrl.dropFilter, value2: this.text},
        url: "useful",
        countNext: data.countNext,
        pageNext: data.pageNext
      })
    },

    sortClick() {
      this.getAllArticles({
        dataFilter: {value1: this.filterCtrl.dropFilter, value2: this.text},
        url: "useful",
        countNext: 8,
        pageNext: {
          page: 1,
          type: 'single'
        }
      })
    },

    clearAll() {
      this.addFilter({type: 'all', id: ''})
    },
  }
}
</script>

<template>

  <div class="articles-by-teva" v-if="getArticles !==null">
    <h1 class="main-title">Корисне</h1>

    <div class="filter-wrapper">

      <div class="line1">
        <div class="btn all hover_type6" @click="addFilter({type:'all', id:''})"
             v-bind:class="[{ active: filterCtrl.all }]">
          <p>Усі</p>
        </div>

        <div v-bind:class="[{ active: filterCtrl.nF.includes(key.id) }]" @click="addFilter({type:'nF', id: key.id})"
             class="btn hover_type6"
             v-for="(key, value) in getArticleNosologies" :key="value">
          <p>{{ key.filterName }}</p>
        </div>
      </div>

      <div class="line2">
        <div class="cont">
          Матеріалів:
          {{ getUsefulTotalPages.totalItem }}
        </div>

        <div class="items-wrapper items-wrapper2">

          <div class="double-block">
            <div class="drop-down-filter">

              <div class="visible hover_type9" @click="drop = !drop">
                <template v-if="filterCtrl.dropFilter ==='byPriorityASC'">
                  За замовчуванням
                </template>
                <template v-if="filterCtrl.dropFilter ==='byDateASC'">
                  За датою публікації
                </template>
                <template v-if="filterCtrl.dropFilter ==='byNameASC'">
                  За алфавітом
                </template>
                <div class="icon-wrapper">

                  <svg class="arrow" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.97046 1.64111L6.92574 5.64111L11.9704 1.64111" stroke="#00A03B" stroke-width="2"
                          stroke-linecap="round"/>
                  </svg>


                </div>
              </div>

              <div class="hide" v-if="drop">

                <div @click="drop = !drop" class="item-active">
                  <template v-if="filterCtrl.dropFilter ==='byPriorityASC'">
                    За замовчуванням
                  </template>
                  <template v-if="filterCtrl.dropFilter ==='byDateASC'">
                    За датою публікації
                  </template>
                  <template v-if="filterCtrl.dropFilter ==='byNameASC'">
                    За алфавітом
                  </template>
                  <svg class="active" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.75 6.61111L5.5 10.5L12.25 3.5" stroke="#00A03B" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round"/>
                  </svg>
                </div>
                <div
                  @click="drop = !drop; filterCtrl.dropFilter = 'byPriorityASC'; sortClick()"
                  v-if="filterCtrl.dropFilter !=='byPriorityASC'" class="item hover_type9">За замовчуванням
                </div>
                <div
                  @click="drop = !drop; filterCtrl.dropFilter = 'byDateASC'; sortClick()"
                  v-if="filterCtrl.dropFilter !=='byDateASC'" class="item hover_type9">За датою публікації
                </div>
                <div
                  @click="drop = !drop; filterCtrl.dropFilter = 'byNameASC'; sortClick()"
                  v-if="filterCtrl.dropFilter !=='byNameASC'" class="item hover_type9">
                  За алфавітом
                </div>
              </div>

            </div>
            <div
              @click="showFilter = !showFilter" class="mobile-btn">
              <div class="icon">
                <svg class="static" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9 15.75C9 15.5511 9.07902 15.3603 9.21967 15.2197C9.36032 15.079 9.55109 15 9.75 15H14.25C14.4489 15 14.6397 15.079 14.7803 15.2197C14.921 15.3603 15 15.5511 15 15.75C15 15.9489 14.921 16.1397 14.7803 16.2803C14.6397 16.421 14.4489 16.5 14.25 16.5H9.75C9.55109 16.5 9.36032 16.421 9.21967 16.2803C9.07902 16.1397 9 15.9489 9 15.75ZM6 11.25C6 11.0511 6.07902 10.8603 6.21967 10.7197C6.36032 10.579 6.55109 10.5 6.75 10.5H17.25C17.4489 10.5 17.6397 10.579 17.7803 10.7197C17.921 10.8603 18 11.0511 18 11.25C18 11.4489 17.921 11.6397 17.7803 11.7803C17.6397 11.921 17.4489 12 17.25 12H6.75C6.55109 12 6.36032 11.921 6.21967 11.7803C6.07902 11.6397 6 11.4489 6 11.25ZM3 6.75C3 6.55109 3.07902 6.36032 3.21967 6.21967C3.36032 6.07902 3.55109 6 3.75 6H20.25C20.4489 6 20.6397 6.07902 20.7803 6.21967C20.921 6.36032 21 6.55109 21 6.75C21 6.94891 20.921 7.13968 20.7803 7.28033C20.6397 7.42098 20.4489 7.5 20.25 7.5H3.75C3.55109 7.5 3.36032 7.42098 3.21967 7.28033C3.07902 7.13968 3 6.94891 3 6.75Z"
                    fill="#00A03B"/>
                </svg>

                <svg v-if="filterCtrl.aF.length>0||filterCtrl.dF.length>0||filterCtrl.nF.length>0" class="red"
                     viewBox="0 0 6 6" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <circle cx="3" cy="3" r="3" fill="#FB3449"/>
                </svg>

              </div>
              Фільтрувати
            </div>
          </div>

          <div class="name-search">
            <div class="icon">
              <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_407_4568)">
                  <path
                    d="M8.42901 2.13873C6.98393 2.13873 5.59664 2.65896 4.49838 3.75723C2.30185 5.95376 2.30185 9.47977 4.49838 11.6185C6.69491 13.815 10.2209 13.815 12.3597 11.6185C14.5562 9.42197 14.5562 5.89595 12.3597 3.75723C11.2614 2.71676 9.8163 2.13873 8.42901 2.13873ZM19.6429 20C19.3539 20 19.0649 19.8844 18.8914 19.711L13.1111 13.9306C10.0475 16.185 5.71225 15.9538 2.93768 13.1792C-0.0680954 10.1734 -0.0680954 5.26012 2.93768 2.25434C5.94347 -0.751445 10.8568 -0.751445 13.8625 2.25434C16.6371 5.0289 16.8683 9.36416 14.5562 12.3699L20.3365 18.1503C20.7412 18.5549 20.7412 19.2486 20.3365 19.711C20.1631 19.9422 19.8741 20 19.6429 20Z"
                    fill="#00A03B"/>
                </g>
                <defs>
                  <clipPath id="clip0_407_4568">
                    <rect width="20" height="20" fill="white" transform="translate(0.68335)"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <the-mask
              mask="SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS" :tokens="hexTokens"
              placeholder="Пошук"
              v-model="text"/>
          </div>

        </div>
      </div>
    </div>

    <div class="articles-wrapper">
      <router-link :to="{name:'page', params:{id: key.id}}" style="text-decoration: none"
                   v-for="(key, value) in getArticles" :key="value" class="item"
                   :class="[{ big:  key.relevance === 'big'}]">
        <picture class="img">
          <source :srcset="`${$root.globalUrl}/uploads/articles/${key.titlePictureMobile}`"
                  media="(max-width: 900px)"/>
          <img :src="`${$root.globalUrl}/uploads/articles/${key.titlePicture}`" alt="">
        </picture>
        <div class="text">

          <img v-if="key.relevancePhoto !== null" class="bg option1" src="@/assets/img/article/pat1.svg" alt="">
          <img v-if="key.relevancePhoto !== null" class="bg option2" src="@/assets/img/article/pat2.svg" alt="">

          <h3 class="title" v-html="key.tile"></h3>
          <h4 class="description" v-html="key.description"></h4>
          <div class="btn">Детальніше</div>
        </div>
      </router-link>

    </div>

    <div v-if="getArticles.length === 0" class="epmty-message">Вибачте, за даним
      запитом
      нічого не знайдено
    </div>

    <div class="nav">
      <div v-if="!getArticleNum.includes(getUsefulTotalPages.totalPage)" class="show-more-btn hover_type5" @click="navigation({countNext:  8 ,pageNext: {
            page:  getUsefulTotalPages.current + 1,
            type:'more'
          }})">
        <p>Показати ще</p>
      </div>

      <div class="pagination-wrapper" v-if="getArticles.filter(e => e.hide).length < 2">
        <div
          class="prev-arrow hover_type8"
          v-if="getUsefulTotalPages.current !== 1" @click="navigation({countNext: 8 ,pageNext: {
            page:getUsefulTotalPages.current - 1,
            type:'single'
          }})">
          <svg viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.41699 1.01123L1.41699 8.8861L9.41699 17.0112" stroke="#00A03B" stroke-width="2"
                  stroke-linecap="round"/>
          </svg>
        </div>

        <div
          class="item-page-num hover_type9"
          v-bind:class="[{ active: getArticleNum.includes(value + 1) }]"
          v-for="(key, value) in getUsefulTotalPages.totalPage"
          @click="navigation({countNext: 8 ,pageNext: {
            page:value + 1,
            type:'single'
          }})">
          {{ value + 1 }}
        </div>

        <div
          class="next-arrow hover_type8"
          v-if="!getArticleNum.includes(getUsefulTotalPages.totalPage)" @click="navigation({countNext: 8 ,pageNext: {
            page:getUsefulTotalPages.current + 1,
            type:'single'
          }})">
          <svg viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.41699 17.0112L9.41699 9.13636L1.41699 1.01123" stroke="#00A03B" stroke-width="2"
                  stroke-linecap="round"/>
          </svg>
        </div>
      </div>
    </div>

    <div class="filter-wrapper mobile-filter" v-if="showFilter">
      <div
        :onclick="`gtag('event', 'click', {  'event_category': 'filter_mobile: кнока назад',  'event_label': '${$route.name}'})`"
        class="back-btn" @click="showFilter = !showFilter">
        <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 1.0002L2 10.9108L12 21" stroke="#424242" stroke-width="2" stroke-linecap="round"/>
        </svg>
        Фільтри
      </div>
      <div class="name">Категорія</div>
      <div class="line1">
        <div class="btn all hover_type6" @click="addFilter({type:'all', id:''})"
             v-bind:class="[{ active: filterCtrl.all }]">
          <p>Усі</p>
        </div>

        <div v-bind:class="[{ active: filterCtrl.nF.includes(key.id) }]" @click="addFilter({type:'nF', id: key.id})"
             class="btn hover_type6"
             v-for="(key, value) in getArticleNosologies" :key="value">
          <p>{{ key.filterName }}</p>
        </div>
      </div>

      <div class="btn-wrapper">
        <div
          :onclick="`gtag('event', 'click', {  'event_category': 'filter_mobile: кнопка очистить',  'event_label': '${$route.name}'})`"
          @click="showFilter = !showFilter; clearAll()" class="clear">Очистити
        </div>
        <div
          :onclick="`gtag('event', 'click', {  'event_category': 'filter_mobile: кнопка применить',  'event_label': '${$route.name}'})`"
          @click="showFilter = !showFilter" class="go">Застосувати
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../assets/style/fonts.css";
@import "../assets/style/vars";

.articles-by-teva {
  max-width: calc(1240px + 20px);
  padding: 20px 20px 50px 20px;
  margin: 0 auto;
  min-height: calc(100vh - 80px - 150px);

  display: flex;
  flex-direction: column;

  .main-title {

    color: $grey;
    font-size: 48px;
    font-family: $EB;
    padding-top: 40px;
    @media screen and (max-width: 768px) {
      font-size: 24px;
      padding-top: 20px;
    }
  }

  .filter-wrapper {
    margin-top: 30px;
    margin-bottom: 30px;

    display: flex;
    flex-direction: column;
    width: 100%;

    &.mobile-filter {
      display: none;

      @media screen and (max-width: 900px) {
        display: flex;
      }

      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      min-height: calc(100vh);
      min-height: calc((var(--vh, 1vh) * 100));

      overflow-y: auto;

      background: white;
      z-index: 100000;
      margin: 0;
      padding: 20px;

      .line1 {
        display: flex;
        margin-bottom: 24px;

        .btn {
          margin: 4px;
          padding: 8px 16px;

          p {
            font-size: 14px;
          }
        }
      }

      .line2 {
        margin-top: 0;
        margin-bottom: 20px;

        .items-wrapper {
          &.items-wrapper1 {
            display: block;

            .item {
              margin-bottom: 10px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      .name {
        margin-bottom: 12px;
        color: black;
        font-family: $M;
        font-size: 16px;
      }

      .back-btn {
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        font-size: 20px;
        font-family: $B;
        color: black;
        cursor: pointer;

        svg {
          max-width: 13px;
          width: 100%;
          display: flex;
          height: auto;
          margin-right: 15px;
        }
      }

      .btn-wrapper {
        margin: auto 0 0 0;

        width: 100%;
        display: flex;
        justify-content: space-between;

        .clear {
          margin-right: 4px;
          border: 2px solid $grey;
          color: $grey;

        }

        .go {
          margin-left: 4px;
          background: $green;
          color: $white;
        }

        > div {
          max-width: 50%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 35px;
          font-size: 14px;
          font-family: $B;
          padding: 15px;
        }
      }
    }

    @media screen and (max-width: 900px) {
      margin-top: 24px;
      margin-bottom: 10px;
    }

    .line1 {
      display: flex;
      flex-flow: row wrap;
      margin: 0 -4px;

      @media screen and (max-width: 900px) {
        display: none;
      }

      .btn {
        user-select: none;

        margin: 5px 4px;

        padding: 10px 24px;

        display: flex;
        justify-content: center;
        align-items: center;

        border: 1px solid $green;
        border-radius: 40px;

        cursor: pointer;
        box-sizing: border-box;

        &.active {
          background: $green;

          p {
            color: $white;
            font-family: $EB;
          }
        }

        &.disable {
          background: $green;
          opacity: 0.4;
          cursor: not-allowed;

          p {
            color: $white;
          }
        }

        p {
          color: $green;
          font-family: $R;
          font-size: 16px;
        }
      }
    }
  }

  .articles-wrapper {

    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 0 -10px;

    .item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      background: $green;
      max-width: calc(25% - 20px);
      width: 100%;
      margin-bottom: 20px;
      margin-left: 10px;
      margin-right: 10px;

      @media screen and (min-width: 1024px) {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0);
        transition: box-shadow 0.2s ease-in-out;

        &:hover {
          box-shadow: 0 0 15px rgba(0, 0, 0, .5);

        }
      }
      @media screen and (max-width: 900px) {
        max-width: calc(50% - 10px);
      }

      @media screen and (max-width: 540px) {
        max-width: calc(100%);
      }

      .text {
        padding: 10px 0 20px 20px;
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        min-height: 170px;
        max-height: 170px;
        margin: auto 0 0 0;
        overflow: hidden;

        .bg {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          width: 100%;
          min-height: 370px;
          max-height: 370px;
          height: initial;
          object-fit: cover;
          pointer-events: none;

          display: none;


          @media screen and (max-width: 900px) {

            min-height: 350px;
            max-height: 350px;
          }
        }

        h3 {
          font-family: $EB;
          font-size: 16px;
          color: white;
          margin-bottom: 5px;
          padding-right: 20px;
          max-height: 41px;

          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        h4 {
          font-family: $R;
          font-size: 14px;
          color: white;
          margin-bottom: 25px;
          padding-right: 20px;
          max-height: 34px;

          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &:nth-child(2n) {
        background: $blue;
      }

      &.big {
        flex-direction: row;
        background: $teal;
        max-width: calc(50% - 20px);
        width: 100%;
        min-height: 370px;
        max-height: 370px;

        .text {
          max-height: initial;
          height: 100%;

          .description {
            max-height: initial;
            height: 100%;
          }
        }

        @media screen and (max-width: 900px) {
          flex-direction: column;
          max-width: calc(50% - 10px);

          min-height: 350px;
          max-height: 350px;
        }

        @media screen and (max-width: 540px) {
          max-width: calc(100%);
        }

        .img {
          img {
            //width: calc(100% - 10px);
          }
        }


        &:nth-child(2n) {
          .text {

            img {
              &.option1 {
                display: none;
              }

              &.option2 {
                display: block;
              }
            }
          }
        }

        &:nth-child(2n+1) {
          .text {

            img {
              &.option2 {
                display: none;
              }

              &.option1 {
                display: block;
              }
            }
          }
        }

        picture {
          max-width: calc(50% - 10px);
          width: 100%;
          max-height: 100%;
          min-height: 0;
          @media screen and (max-width: 900px) {
            max-width: 100%;
          }
        }

        .text {
          max-width: calc(50% + 10px);
          width: 100%;
          height: 100%;

          padding: 40px 0 20px 40px;

          @media screen and (max-width: 900px) {
            max-height: 170px;
            padding: 10px 0 20px 20px;
            max-width: 100%;
          }

          .bg {
            width: calc(100% + 10px);
            left: 0;
          }

          .title {
            padding-right: 20px;
            margin-bottom: 10px;
            max-height: initial;

            display: -webkit-box;
            -webkit-line-clamp: initial;
            -webkit-box-orient: initial;
            text-overflow: initial;
            overflow: visible;

            @media screen and (max-width: 900px) {
              max-height: 41px;

              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;

            }
          }

          .description {
            padding-right: 20px;
            @media screen and (max-width: 900px) {
              max-height: 34px;

              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      picture {
        max-width: 100%;
        width: 100%;
        max-height: 200px;
        min-height: 200px;
        height: 100%;
        display: block;

        @media screen and (max-width: 900px) {
          min-height: 180px;
          max-height: 180px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;

        }
      }

      .btn {
        background: white;
        margin: auto 0 0 auto;
        padding: 8px 14px;
        border-radius: 20px 0 0 20px;
        font-size: 9px;
        color: #00A03B;
        font-family: $R;

        transition: background-color .2s ease, color .2s ease;

        @media screen and (min-width: 1024px) {

          &:hover {
            color: white;
            background: rgba(255, 255, 255, 0);
          }
        }
      }
    }
  }

  .nav {
    margin: auto auto 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .show-more-btn {
    max-width: 230px;
    min-width: 230px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 40px 0 0 0;

    border: 2px solid $green;
    border-radius: 35px;
    cursor: pointer;

    p {
      color: $green;
      font-family: $EB;
    }
  }

  .pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    width: max-content;
    margin: 30px 0 0 0;

    .prev-arrow, .next-arrow {
      margin: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid $white;
      border-radius: 50%;

      width: 40px;
      height: 40px;

      position: absolute;
      top: 0;

      &.prev-arrow {
        left: -50px;
      }

      &.next-arrow {
        right: -50px;
      }

      svg {
        display: block;

        width: 11px;
        height: auto;
      }
    }

    .item-page-num {
      cursor: pointer;
      width: 40px;
      height: 40px;
      margin: 0 3px;

      display: flex;
      justify-content: center;
      align-items: center;

      border: 2px solid $white;
      border-radius: 50%;

      color: $grey;
      font-size: 16px;
      font-family: $R;

      &.active {
        border-color: $green;
        color: $green;
        font-family: $EB;

      }
    }
  }

  .epmty-message {
    font-family: $R;
    font-size: 24px;
    color: $grey;

    max-width: 394px;
    width: 100%;

    text-align: center;
    margin: auto auto;
  }

  .line2 {
    margin-top: 29px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 900px) {
      margin-top: 0;
    }

    .items-wrapper {
      display: flex;
      align-items: center;
      margin: 0 -10px;
      @media screen and (max-width: 900px) {
        margin: 0;
      }

      &.items-wrapper1 {

        .item {
          cursor: pointer;
          user-select: none;

          &.active {
            .square {
              .arrow {
                opacity: 1;
              }
            }
          }

          &.disable {
            opacity: 0.4;
            cursor: not-allowed;
          }

          .square {
            width: 20px;
            height: 20px;

            border: 1px solid #B6B6B6;
            margin-right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            .arrow {
              opacity: 0;

              svg {
                width: 14px;
                height: auto;
                display: block;
              }
            }
          }

          p {
            color: $grey;
            font-family: $R;
            font-size: 14px;
          }
        }

        @media screen and (max-width: 900px) {
          display: none;
        }
      }

      &.items-wrapper2 {
        display: flex;
        align-items: center;
        @media screen and (max-width: 900px) {
          flex-direction: column-reverse;
          width: 100%;
        }


        .double-block {
          max-width: max-content;
          width: 100%;

          @media screen and (max-width: 900px) {
            display: flex;
            justify-content: space-between;
            max-width: 100%;
            width: 100%;
            margin-top: 18px;
          }

          .drop-down-filter {
            user-select: none;
            position: relative;
            padding: 13px 0;

            .visible {
              cursor: pointer;
              display: flex;
              align-items: center;

              font-family: $R;
              font-size: 14px;
              color: $grey;

              .icon-wrapper {
                display: flex;
                align-items: center;
                margin-left: 8px;

                .arrow {
                  max-width: 10px;
                  width: 100%;
                  height: auto;
                  display: block;
                }
              }
            }

            .hide {
              z-index: 20;
              position: absolute;
              top: 0;
              right: 0;
              padding: 13px 18px;
              min-width: 200px;

              background: #FFFFFF;
              box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

              @media screen and (max-width: 900px) {
                right: auto;
                left: 0;
              }

              .item-active {
                color: $grey;
                font-family: $B;
                font-size: 14px;
                margin-bottom: 16px;

                display: flex;
                align-items: center;
                justify-content: space-between;

                .active {
                  margin-left: 8px;
                  max-width: 14px;
                  width: 100%;
                  height: auto;
                  display: block;
                }
              }

              .item {
                cursor: pointer;
                margin-bottom: 16px;
                font-family: $R;
                font-size: 14px;
                color: $grey;

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }

          .mobile-btn {
            display: none;
            @media screen and (max-width: 900px) {
              display: flex;
            }
            align-items: center;

            .icon {
              position: relative;

              .static {
                max-width: 24px;
                width: 100%;
                height: auto;
                display: block;
              }

              .red {
                position: absolute;
                right: 0;
                top: 2px;
                max-width: 6px;
                width: 100%;
                height: auto;
                display: block;
              }
            }

            color: $green;
            font-family: $M;
            font-size: 14px;

          }
        }

        .name-search {
          position: relative;
          width: 100%;

          input {
            max-width: 300px;
            width: 100%;
            padding: 10px 35px 10px 18px;

            border: 1px solid #B6B6B6;
            box-sizing: border-box;
            border-radius: 40px;
            position: relative;
            outline: none;
            font-family: $M;
            font-size: 14px;
            color: $grey;

            @media screen and (max-width: 900px) {
              max-width: 900px;
            }

            &:focus {
              border: 1px solid #00A03B !important;

            }

            &::placeholder {
              font-size: 14px;
              font-family: $R;
              opacity: 0.6;
            }
          }

          .icon {
            width: 20px;
            height: 20px;

            position: absolute;
            right: 10px;
            z-index: 20;
            top: 0;
            bottom: 0;

            margin: auto 0;

            svg {
              width: 100%;
              height: auto;
              display: block;
            }
          }
        }
      }

      > div {
        margin: 0 10px;
        display: flex;
        align-items: center;
      }
    }

    .cont {
      color: $teal;
      font-size: 14px;
      font-family: $R;
      @media screen and (max-width: 900px) {
        display: none;
      }
    }
  }
}
</style>